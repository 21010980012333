:root {
    --fontdefaultcolour: var(--fontdarkcolour);
    --fontdisabledcolour: var(--fontdarkcolour);
    --fonttitlecolour: var(--fontdarkcolour);
    --contentbackground: #FFFFFF;
    --tableheaderbackground: #F7F7F7;
    --tablecontentbackground: #FFFFFF;
    --tablecontentborder: #E0E0E0;
    --tablecontenthover: #EBEBEB;
    --tablecontentselected: #EBEBEB;

    //Bootstrap default overrides
    --bs-font-base-color: var(--fontdefaultcolour) !important;
    --bs-light: var(--fontlightcolour) !important;
    --bs-dark: var(--fontdarkcolour) !important;
    --bs-primary: var(--primarycolour) !important;
    --bs-primary-border-subtle: var(--primarydisabledcolour) !important;
    --bs-primary-color: var(--primaryfontcolour) !important;
    --bs-secondary: var(--secondarycolour) !important;
    --bs-secondary-border-subtle: var(--secondarydisabledcolour) !important;
    --bs-secondary-color: var(--secondaryfontcolour) !important;
    --bs-success: var(--successcolour) !important;
    --bs-success-border-subtle: var(--successdisabledcolour) !important;
    --bs-success-color: var(--successfontcolour) !important;
    --bs-warning: var(--warningcolour) !important;
    --bs-warning-border-subtle: var(--warningdisabledcolour) !important;
    --bs-warning-color: var(--warningfontcolour) !important;
    --bs-danger: var(--dangercolour) !important;
    --bs-danger-border-subtle: var(--dangerdisabledcolour) !important;
    --bs-danger-color: var(--dangerfontcolour) !important;
    --bs-info: var(--infocolour) !important;
    --bs-info-border-subtle: var(--infodisabledcolour) !important;
    --bs-info-color: var(--infofontcolour) !important;
    --bs-link-color: var(--bs-primary) !important;
    --bs-link-hover-color: var(--primaryhovercolour) !important;
    --bs-body-font-weight: 500;
}


//General
.breadcrumb .breadcrumb-item.active a, 
.breadcrumb .breadcrumb-item.active,
input.subheader, 
select.subheader,
button.subheader,
.form-control.subheader, 
.list-group-item,
.dashboard-filter-dropdown,
body {
    color: var(--fontdefaultcolour) !important;
}
body {
    background-color: var(--platformbackgroundcolour) !important;
}

//Bootstrap default buttons
.btn-primary {
    --bs-btn-color: var(--bs-primary-color) !important;
    --bs-btn-bg: var(--bs-primary) !important;
    --bs-btn-border-color: var(--bs-primary-border-subtle) !important;
    --bs-btn-hover-color: var(--bs-primary-color) !important;
    --bs-btn-hover-bg: var(--primaryhovercolour) !important;
    --bs-btn-hover-border-color: var(--bs-primary-border-subtle) !important;
    --bs-btn-focus-shadow-rgb: var(--bs-primary-border-subtle) !important;
    --bs-btn-active-color: var(--bs-primary-color) !important;
    --bs-btn-active-bg: var(--primaryhovercolour) !important;
    --bs-btn-active-border-color: var(--primaryhovercolour) !important;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
    --bs-btn-disabled-color: var(--bs-primary-color) !important;
    --bs-btn-disabled-bg: var(--bs-primary-border-subtle) !important;
    --bs-btn-disabled-border-color: var(--bs-primary-border-subtle) !important;
}
.btn-outline-primary {
    --bs-btn-color: var(--bs-primary) !important;
    --bs-btn-border-color: var(--bs-primary) !important;
    --bs-btn-hover-color: var(--bs-primary-color) !important;
    --bs-btn-hover-bg: var(--bs-primary) !important;
    --bs-btn-hover-border-color: var(--bs-primary) !important;
    --bs-btn-focus-shadow-rgb: var(--bs-primary-border-subtle) !important;
    --bs-btn-active-color: var(--bs-primary-color) !important;
    --bs-btn-active-bg: var(--bs-primary) !important;
    --bs-btn-active-border-color: var(--bs-primary) !important;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: var(--bs-primary) !important;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: var(--bs-primary) !important;
    --bs-gradient: none;
}
.btn-secondary {
    --bs-btn-color: var(--bs-secondary-color) !important;
    --bs-btn-bg: var(--bs-secondary) !important;
    --bs-btn-border-color: var(--bs-secondary-border-subtle) !important;
    --bs-btn-hover-color: var(--bs-secondary-color) !important;
    --bs-btn-hover-bg: var(--secondaryhovercolour) !important;
    --bs-btn-hover-border-color: var(--bs-secondary-border-subtle) !important;
    --bs-btn-focus-shadow-rgb: var(--bs-secondary-border-subtle) !important;
    --bs-btn-active-color: var(--bs-secondary-color) !important;
    --bs-btn-active-bg: var(--secondaryhovercolour) !important;
    --bs-btn-active-border-color: var(--secondaryhovercolour) !important;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
    --bs-btn-disabled-color: var(--bs-secondary-color) !important;
    --bs-btn-disabled-bg: var(--bs-secondary-border-subtle) !important;
    --bs-btn-disabled-border-color: var(--bs-secondary-border-subtle) !important;
}
.btn-success {
    --bs-btn-color: var(--bs-success-color) !important;
    --bs-btn-bg: var(--bs-success) !important;
    --bs-btn-border-color: var(--bs-success-border-subtle) !important;
    --bs-btn-hover-color: var(--bs-success-color) !important;
    --bs-btn-hover-bg: var(--successhovercolour) !important;
    --bs-btn-hover-border-color: var(--bs-success-border-subtle) !important;
    --bs-btn-focus-shadow-rgb: var(--bs-success-border-subtle) !important;
    --bs-btn-active-color: var(--bs-success-color) !important;
    --bs-btn-active-bg: var(--successhovercolour) !important;
    --bs-btn-active-border-color: var(--successhovercolour) !important;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
    --bs-btn-disabled-color: var(--bs-success-color) !important;
    --bs-btn-disabled-bg: var(--bs-success-border-subtle) !important;
    --bs-btn-disabled-border-color: var(--bs-success-border-subtle) !important;
}
.btn-warning {
    --bs-btn-color: var(--bs-warning-color) !important;
    --bs-btn-bg: var(--bs-warning) !important;
    --bs-btn-border-color: var(--bs-warning-border-subtle) !important;
    --bs-btn-hover-color: var(--bs-warning-color) !important;
    --bs-btn-hover-bg: var(--warninghovercolour) !important;
    --bs-btn-hover-border-color: var(--bs-warning-border-subtle) !important;
    --bs-btn-focus-shadow-rgb: var(--bs-warning-border-subtle) !important;
    --bs-btn-active-color: var(--bs-warning-color) !important;
    --bs-btn-active-bg: var(--warninghovercolour) !important;
    --bs-btn-active-border-color: var(--warninghovercolour) !important;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
    --bs-btn-disabled-color: var(--bs-warning-color) !important;
    --bs-btn-disabled-bg: var(--bs-warning-border-subtle) !important;
    --bs-btn-disabled-border-color: var(--bs-warning-border-subtle) !important;
}
.btn-danger {
    --bs-btn-color: var(--bs-danger-color) !important;
    --bs-btn-bg: var(--bs-danger) !important;
    --bs-btn-border-color: var(--bs-danger-border-subtle) !important;
    --bs-btn-hover-color: var(--bs-danger-color) !important;
    --bs-btn-hover-bg: var(--dangerhovercolour) !important;
    --bs-btn-hover-border-color: var(--bs-danger-border-subtle) !important;
    --bs-btn-focus-shadow-rgb: var(--bs-danger-border-subtle) !important;
    --bs-btn-active-color: var(--bs-danger-color) !important;
    --bs-btn-active-bg: var(--dangerhovercolour) !important;
    --bs-btn-active-border-color: var(--dangerhovercolour) !important;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
    --bs-btn-disabled-color: var(--bs-danger-color) !important;
    --bs-btn-disabled-bg: var(--bs-danger-border-subtle) !important;
    --bs-btn-disabled-border-color: var(--bs-danger-border-subtle) !important;
}
.btn-outline-danger {
    --bs-btn-color: var(--bs-danger) !important;
    --bs-btn-border-color: var(--bs-danger) !important;
    --bs-btn-hover-color: var(--bs-danger-color) !important;
    --bs-btn-hover-bg: var(--bs-danger) !important;
    --bs-btn-hover-border-color: var(--bs-danger) !important;
    --bs-btn-focus-shadow-rgb: var(--bs-danger-border-subtle) !important;
    --bs-btn-active-color: var(--bs-danger-color) !important;
    --bs-btn-active-bg: var(--bs-danger) !important;
    --bs-btn-active-border-color: var(--bs-primary) !important;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: var(--bs-danger) !important;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: var(--bs-danger) !important;
    --bs-gradient: none;
}
.btn-info {
    --bs-btn-color: var(--bs-info-color) !important;
    --bs-btn-bg: var(--bs-info) !important;
    --bs-btn-border-color: var(--bs-info-border-subtle) !important;
    --bs-btn-hover-color: var(--bs-info-color) !important;
    --bs-btn-hover-bg: var(--infohovercolour) !important;
    --bs-btn-hover-border-color: var(--bs-info-border-subtle) !important;
    --bs-btn-focus-shadow-rgb: var(--bs-info-border-subtle) !important;
    --bs-btn-active-color: var(--bs-info-color) !important;
    --bs-btn-active-bg: var(--infohovercolour) !important;
    --bs-btn-active-border-color: var(--infohovercolour) !important;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
    --bs-btn-disabled-color: var(--bs-info-color) !important;
    --bs-btn-disabled-bg: var(--bs-info-border-subtle) !important;
    --bs-btn-disabled-border-color: var(--bs-info-border-subtle) !important;
}
button.btn-primary.focus:not(.page-item, .nr-per-page), button.btn-primary:focus:not(.page-item, .nr-per-page),
button.btn-outline-primary.focus:not(.page-item, .nr-per-page), button.btn-outline-primary:focus:not(.page-item, .nr-per-page) {
	box-shadow: 0 0 0 0.2rem var(--bs-primary-border-subtle) !important;
}
button.btn-secondary.focus, button.btn-secondary:focus,
button.btn-outline-secondary.focus, button.btn-outline-secondary:focus {
	box-shadow: 0 0 0 0.2rem var(--bs-secondary-border-subtle) !important;
}
button.btn-success.focus, button.btn-success:focus,
button.btn-outline-success.focus, button.btn-outline-success:focus {
	box-shadow: 0 0 0 0.2rem var(--bs-success-border-subtle) !important;
}
button.btn-warning.focus, button.btn-warning:focus,
button.btn-outline-warning.focus, button.btn-outline-warning:focus {
	box-shadow: 0 0 0 0.2rem var(--bs-warning-border-subtle) !important;
}
button.btn-danger.focus, button.btn-danger:focus,
button.btn-outline-danger.focus, button.btn-outline-danger:focus {
	box-shadow: 0 0 0 0.2rem var(--bs-danger-border-subtle);
}
button.btn-info.focus, button.btn-info:focus,
button.btn-outline-info.focus, button.btn-outline-info:focus {
	box-shadow: 0 0 0 0.2rem var(--bs-info-border-subtle);
}

//Branding
.header-general, 
.header-general .btn {
    background-color: var(--headercolour) !important;
    border-color: var(--headercolour) !important;
    color: var(--headerfontcolour) !important;
}
a.dropdown-toggle.header-font {
    color: var(--headerfontcolour) !important;
}
.bg-subtle-primary {
    color: var(--bs-primary-color) !important;
    background-color: var(--bs-primary-border-subtle) !important;
}
.documentHeader,
.items-per-page-nr,
.square-perform-action,
.requestHeader .btn-primary,
.active > .page-link {
    background-color: var(--bs-primary) !important;
    color: var(--bs-primary-color) !important;
}
.requestHeader .btn-outline-primary,
.emailPreviewBackButton  {
    border-color: var(--bs-primary) !important;
    color: var(--bs-primary) !important;
}
.breadcrumb-item + .breadcrumb-item::before:not(.active),
.breadcrumb .breadcrumb-item:not(.active) a,
li.page-item a {
    color: var(--bs-primary) !important;
}

.bg-primary.progress-bar {
    background-color: var(--bs-primary) !important;
}
.breadcrumb-item + .breadcrumb-item::before,
.breadcrumb .breadcrumb-item a:hover {
    color: var(--primaryhovercolour) !important;
}
.requestBuilderHeaderLine {
    border-top: 1px dashed var(--bs-primary-border-subtle) !important;
}
.requestBuilderHeaderLine.available {
    border-top: 1px dashed var(--bs-primary) !important;
}
.nav-link.nav-item {
    color: var(--bs-primary) !important;
}
.nav-link.nav-item.disabled, .builder-navigation .nav-link.disabled > h6, .builder-navigation .nav-link.disabled > i {
    color: var(--bs-primary-border-subtle) !important;
}
a:not(.dropdown-item, .dropdown-group-left) {
    color: var(--bs-primary) !important;
}
a.back-to-dashboard {
    color: var(--fontdefaultcolor) !important;
}
.square-perform-action:hover,
.requestHeader .btn-outline-primary:hover,
.requestHeader .btn-primary:hover,
.emailPreviewBackButton:hover {
	background-color: var(--primaryhovercolour) !important;
	border-color: var(--primaryhovercolour) !important;
	color: var(--bs-primary-color) !important;
}
.square-perform-action:disabled,
.requestHeader .btn-primary:disabled,
.switch-form-default.form-check-input:disabled:checked {
    background-color: var(--bs-primary-border-subtle) !important;
    border-color: var(--bs-primary-border-subtle) !important;
    color: var(--bs-primary-color) !important;
}
.performaction-sub-navbar .btn:not(.btn-success) {
    background-color: var(--bs-secondary) !important;
    border-color: var(--bs-secondary) !important;
    color: var(--bs-secondary-color) !important;
}
.dropdown-item:hover, a.dropdown-item:hover {
    background-color: var(--primaryhovercolour) !important;
    border-color: var(--primaryhovercolour) !important;
    color: var(--bs-primary-color) !important;
}
.navigation .active {
    color: var(--bs-primary) !important;
}
.performaction-sub-navbar .btn:not(.btn-success):hover {
    background-color: var(--secondaryhovercolour) !important;
    border-color: var(--secondaryhovercolour) !important;
    color: var(--bs-secondary-color) !important;
}
.completedModal-backdrop {
    top:100px!important;
	--bs-backdrop-opacity: 1 !important;
	background-color: var(--platformbackgroundcolour);
	opacity: 1 !important;
}
body:disabled {
    color: var(--fontdisabledcolour) !important;
    opacity: 0.5 !important;
}

h1, h2, h3, h4, h5, .option-item-small.title {
    color: var(--fonttitlecolour) !important;
}

.bg-success, .placeholder-field.active {
    background-color: var(--bs-success) !important;
    color: var(--bs-success-color) !important;
}
.bg-success:disabled {
    background-color: var(--bs-success-border-subtle) !important;
    color: var(--bs-success-color) !important;
}
.bg-warning {
    background-color: var(--bs-warning) !important;
    border-color: var(--warninghovercolour) !important;
    color: var(--bs-warning-color) !important;
}
.alert.workflowPlaceholder.alert-info {
    background-color: var(--bs-info) !important;
    border-color: var(--infohovercolour) !important;
    color: var(--bs-info-color) !important;
}
.alert.workflowPlaceholder.alert-outline-info {
    border-color: var(--infohovercolour) !important;
    color: var(--bs-info) !important;
}

// Text
.text-primary {
    color: var(--bs-primary) !important;
}
.text-secondary {
    color: var(--bs-secondary) !important;
}
.text-success {
    color: var(--bs-success) !important;
}
.text-warning {
    color: var(--bs-warning) !important;
}
.text-danger {
    color: var(--bs-danger) !important;
}
.text-info {
    color: var(--bs-info) !important;
}

// Alerts
.alert {
    font-size: var(--font-size-alert);
}
.alert-primary {
    background: var(--bs-primary-border-subtle) !important;
    border-color: var(--primaryhovercolour) !important;
    color: var(--bs-primary-color) !important;
}
.alert-secondary {
    background: var(--bs-secondary-border-subtle) !important;
    border-color: var(--secondaryhovercolour) !important;
    color: var(--bs-secondary-color) !important;
}
.alert-success {
    background: var(--bs-success-border-subtle) !important;
    border-color: var(--successhovercolour) !important;
    color: var(--bs-success-color) !important;
}
.alert-warning {
    background: var(--bs-warning-border-subtle) !important;
    border-color: var(--warninghovercolour) !important;
    color: var(--bs-warning-color) !important;
}
.alert-danger {
    background: var(--bs-danger-border-subtle) !important;
    border-color: var(--dangerhovercolour) !important;
    color: var(--bs-danger-color) !important;
}
.alert-info {
    background: var(--bs-info-border-subtle) !important;
    border-color: var(--infohovercolour) !important;
    color: var(--bs-info-color) !important;
}
.alert-primary.disabled,
.alert-secondary.disabled,
.alert-success.disabled,
.alert-warning.disabled,
.alert-danger.disabled,
.alert-info.disabled {
    opacity: .5;
}

.was-validated .form-control:invalid, 
.form-control.is-invalid, 
.form-control.ng-invalid.ng-dirty,.form-control.ng-invalid.ng-touched,
.btn.rendereddocumentsummary-summary-dropdown.required {
    border: 1px solid var(--bs-danger);
}
.form-control.is-invalid:focus, 
.form-control.ng-invalid.ng-dirty:focus,
.form-control.ng-invalid.ng-touched:focus {
    border: 1px solid var(--bs-danger) !important;
    box-shadow: 0 0 5px var(--dangerhovercolour) !important; /* Add your desired box shadow properties here */
}
ul#documentSection.required li:first-child,
ul#personSection.required li:first-child {
    border-top: 1px solid var(--dangerhovercolour) !important;
	border-right: 1px solid var(--dangerhovercolour) !important;
	border-left: 1px solid var(--dangerhovercolour) !important;
}
ul#documentSection.required li:last-child,
ul#personSection.required li:last-child {
	border-right: 1px solid var(--dangerhovercolour) !important;
	border-bottom: 1px solid var(--dangerhovercolour) !important;
	border-left: 1px solid var(--dangerhovercolour) !important;
}
ul#documentSection.required li:not(:first-child),
ul#documentSection.required li:not(:last-child),
ul#personSection.required li:not(:first-child),
ul#personSection.required li:not(:last-child) {
	border-right: 1px solid var(--dangerhovercolour) !important;
	border-left: 1px solid var(--dangerhovercolour) !important;
}
button#dropdownMenu.personSection.required {
    border: 1px solid var(--dangerhovercolour) !important;
}
.form-control.rendereddocumentsummary-summary-dropdown.required {
	border-bottom: 1px solid var(--dangerhovercolour) !important;
	border-left: 1px solid var(--dangerhovercolour) !important;
    border-top: 1px solid var(--dangerhovercolour) !important;
}
.input-group-text.required {
    border-top: 1px solid var(--dangerhovercolour) !important;
    border-right: 1px solid var(--dangerhovercolour) !important;
    border-bottom: 1px solid var(--dangerhovercolour) !important;
}
.invalid-feedback,
.bi.error,
.text-helper.invalid {
	color: var(--bs-danger) !important;
}
.statusHeader .btn-outline-danger {
    border-color: var(--bs-danger) !important;
    color: var(--bs-danger) !important;
}
.statusHeader .btn-outline-danger:hover {
    background-color: var(--dangerhovercolour) !important;
    border-color: var(--dangerhovercolour) !important;
    color: var(--bs-danger-color) !important;
}
.tooltip, .tooltip-inner {
    background-color: #191919 !important;
    color: #FFFFFF !important;
}

//Other (Light mode)
//Content background
.bg-white, .sub-header, .performaction-sub-navbar {
    background-color: var(--contentbackground) !important;
}

//Forms
//Default
.form-control {
    background-color: #FFFFFF !important;
}
.form-control input, .form-control textarea {
    border: #E0E0E0 1px solid !important;
    border-radius: 5px !important;
}
input::placeholder,
textarea::placeholder {
	color: var(--fontdisabledcolour) !important;
    opacity: 50% !important;
}
.form-control,
i.bi.bi-question-circle,
.form-label {
    color: var(--fontdefaultcolour);
}
span.text-secundary {
    color: var(--bs-secondary) !important;
}

//Active
.form-control:focus,
.form-control:focus-visible {
    background: hsl(0, 0%, 100%);
    border: #A6CBF3 1px solid !important;
    outline: 0 !important;
}
input::placeholder:focus,
textarea::placeholder:focus {
	color: var(--fontdisabledcolour) !important;
    opacity: 50% !important;
}

// //Read only
.form-control:disabled {
	color: #6C757D !important;
    background-color: #EAECEF !important;
}
input::placeholder:disabled,
textarea::placeholder:disabled {
	color: #6C757D !important;
}
.form-control:disabled {
    border: #CED4DA 1px solid !important;
    border-radius: 5px !important;
}

//Tables
.list-group-item.subheader, 
.subheader.cdk-drag-preview,
.request-status-table-header {
    // font-family: "Lato" !important;
    // font-style: normal;
    // background-color: var(--tableheaderbackground) !important;
    // color: var(--bs-secondary) !important;
    font-family: "Lato" !important;
    font-style: normal !important;
    font-size: var(--font-size-listitem) !important;
    font-weight: 100 !important;
    color: var(--bs-secondary) !important;
}
.list-group-item.subheader .bi.bi-caret-down-fill, .subheader.cdk-drag-preview.bi.bi-caret-down-fill,
.list-group-item.subheader .bi.bi-caret-up-fill, .subheader.cdk-drag-preview.bi.bi-caret-up-fill {
    color: var(--fontdefaultcolour) !important;
}
.list-group-item:not(.subheader, .request-status-table-header, .subtable, .active) {
    background-color: var(--tablecontentbackground) !important;
    border: var(--tablecontentborder) 1px solid;
}
.list-group-item:not(.subheader, .request-status-table-header, .certificate, .subtable, .active):hover {
    background-color: var(--tablecontenthover) !important;
    color: var(--fontdefaultcolour) !important;
}
.list-group-item:not(.subheader):focus {
    background-color: var(--bs-primary) !important;
}
// active and selected list-group-item {
.list-group-item + .list-group-item.active {
    background: var(--tablecontentselected) 0% 0% no-repeat padding-box !important;
    border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color) !important;
    opacity: 1 !important;
    margin-top: 0px !important;
}

//Radio / Checkbox 
.checkbox.form-check-input,
.form-check-input,
.radiobutton.form-check-input {
    background-color: var(--bs-primary) !important;
    border: var(--bs-primary) 1px solid !important;
}
.checkbox.form-check-input:not(:checked),
.form-check-input:not(:checked),
.radiobutton.form-check-input:not(:checked) {
    background-color: #FFFFFF !important;
    border: #B6B6B6 1px solid !important;
}
.checkbox.form-check-input:disabled:not(:checked),
.form-check-input:disabled:not(:checked),
.radiobutton.form-check-input:disabled:not(:checked) {
    background-color: #FFFFFF !important;
    border: #DBDBDB 1px solid !important; 
}
.checkbox.form-check-input:checked,
.form-check-input:checked,
.radiobutton.form-check-input:checked {
    background-color: var(--bs-primary) !important;
    border: var(--bs-primary) 1px solid !important;
}
.checkbox.form-check-input:disabled:checked,
.form-check-input:disabled:checked,
.radiobutton.form-check-input:disabled:checked {
    background-color: var(--bs-primary-border-subtle) !important;
    border: var(--bs-primary-border-subtle) 1px solid !important;
}
.form-check-input[type="checkbox"]:not(.switch-form-default) {
	border: 1px solid #B6B6B6;
	border-radius: 3px !important;
}

//Switches
.switch-form-default.form-check-input {
    background-color: var(--bs-primary) !important;
    border: var(--bs-primary) 1px solid !important;
}
.switch-form-default.form-check-input:not(:checked) {
    background-color: #FFFFFF !important;
    border: #BFBFBF 1px solid !important;
}
.switch-form-default.form-check-input:disabled:not(:checked) {
    background-color: transparent !important;
    border: #BFBFBF 1px solid !important;
    color: #BFBFBF;
}
.switch-form-default.form-check-input:checked {
    border: var(--bs-primary-border-subtle) 3px solid !important;
    background-color: var(--bs-primary) !important;
    color: var(--bs-primary-color) !important;
}
.switch-form-default.form-check-input:disabled:checked {
    border: var(--bs-primary-border-subtle) 1px solid !important;
}

//Helpertext
.text-muted,
.form-text.text-muted,
.helper-text,
label.disabled {
    color: var(--fontdisabledcolour) !important;
    opacity: 0.5 !important;
}

//Viewer
#actionIndicator {
	border-left: 1px dashed var(--bs-primary);
}

//Pagination
.pagination {
    --bs-pagination-padding-x: 0.75rem !important;
    --bs-pagination-padding-y: 0.375rem !important;
    --bs-pagination-font-size: 1rem !important;
    --bs-pagination-color: var(--bs-link-color) !important;
    --bs-pagination-bg: var(--bs-body-bg) !important;
    --bs-pagination-border-width: var(--bs-border-width) !important;
    --bs-pagination-border-color: var(--bs-border-color) !important;
    --bs-pagination-border-radius: var(--bs-border-radius) !important;
    --bs-pagination-hover-color: var(--bs-link-hover-color) !important;
    --bs-pagination-hover-bg: var(--bs-tertiary-bg) !important;
    --bs-pagination-hover-border-color: var(--bs-border-color) !important;
    --bs-pagination-focus-color: var(--bs-link-hover-color) !important;
    --bs-pagination-focus-bg: var(--bs-secondary-bg) !important;
    --bs-pagination-focus-box-shadow: 0 0 0 0.25rem var(--bs-primary-border-subtle) !important;
    --bs-pagination-active-color: #fff !important;
    --bs-pagination-active-bg: var(--bs-primary) !important;
    --bs-pagination-active-border-color: var(--bs-primary) !important;
    --bs-pagination-disabled-color: var(--bs-secondary-color);
    --bs-pagination-disabled-bg: var(--bs-secondary-bg) !important;
    --bs-pagination-disabled-border-color: var(--bs-border-color) !important;
    display: flex;
    padding-left: 0;
    list-style: none;
}
.pagination .button-group {
    border-right: 0px !important;
}

// Settings navigation
.navigation button:hover {
	background: var(--tablecontenthover) !important;
	color: var(--bs-black);
}
.navigation .active {
	background: var(--tablecontentselected) !important;
	color: var(--bs-primary) !important;
}

.error-message {
    color: var(--bs-font-base-color) !important;
    position: relative;
    top: 100px !important;
}
.error-404 {
    font-size: 18px;
}
.error-404-smaller {
    font-size: 14px;
}
i.error-icon {
    color: var(--bs-font-base-color) !important;
    font-size: 65px;
}

//User identification modal
.user-identification-details{
    font-size: var(--bs-body-font-size) !important;
}