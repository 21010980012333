li.breadcrumb-item, li.breadcrumb-item > a {
	text-decoration: none;
	// color: $subheader-font-default-color !important
}
li.breadcrumb-item.active, li.breadcrumb-item.active > a {
	// color: $subheader-font-active-color !important;
}
.navigation {
	margin-right: 14px !important;
}
.navigation button {
	font-weight: bolder;
	margin: 0px 10px 5px -4px;
	padding-left: 10px;
	padding-right: 10px;
	text-align: left !important;
	text-decoration: none;
	width: 100%;
}
.navigation i {
	margin-right: 10px;
}
.navigation .submenu {
	margin-left: 27px !important;
}
.navigation .submenu button {
	font-weight: normal !important;
}
.navigation-settings {
	padding: 0px 20px 4px 24px !important;
}
ol.breadcrumb {
	margin-bottom: 0px;
}