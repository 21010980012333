/* Same height as Info element */
app-request-status-log table {
    height: 242px;
}

.status-eventlog-list {
    color: var(--fontdefaultcolour) !important;
    font-size: var(--font-size-listitem) !important;
    min-height: 190px;
}
.status-eventlog-list > tbody {
    padding: 14px 0px 6px 0px;
}
tr.mat-mdc-row.small-row {
    height: 18px !important;
}
.small-row > td.mat-mdc-cell {
    border-top: none !important;
}

/* Scrollbars */
.status-eventlog-list > tbody {
    border: 0px;
    display: block;
    max-height: 196px;
    overflow-y: auto;
    width: 100%;
}
.status-eventlog-list > tbody:focus, .status-eventlog-list > tbody:focus-visible {
    border: 0px !important;
    outline: none;
}
.status-eventlog-list > tbody::-webkit-scrollbar {
    background: #E9ECEF 0% 0% no-repeat padding-box;
    border-radius: var(--pki-border-radius) !important;
	cursor: hand !important;
    width: 9px !important;
    z-index: 1000;
}
.status-eventlog-list > tbody::-webkit-scrollbar-thumb {
    background-color: var(--bs-secondary);
    border-radius: var(--pki-border-radius) !important;
    cursor: hand !important;
}
.status-eventlog-list > tbody::-webkit-scrollbar-track {
    cursor: hand !important;
}

/* Specific badges */
.status-eventlog-list div.badge-listitem {
    float: left;
    font-size: 2px !important;
    height: 5px !important;
    margin-top: 4px;
    margin-right: 4px;
    width: 5px !important;
}

/* Specific moble styling */
.accordion-mobile-item {
    min-height: 55px;
}
.accordion-mobile-item:not(:last-child) {
    border-bottom: 1px solid var(--tablecontentborder);
}
.accordion-item.sendgroup {
    border-top: 0px !important;
    border-radius: 0px !important;
}