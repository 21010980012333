.btn-shadow {
	box-shadow: 0px 3px 6px #00000029 !important;
}
.dialog-footer-mobile {
	bottom: -50px;
	position: relative;
}
i.grip-placeholder-field {
	color: #ffffff;
	float: left;
	font-size: 30px;
	margin-left: -3px;
}
img.pdf-preparation-dialog-page {
	max-height: 90% !important;
	max-width: 90%;
}
.child.active {
	align-content: center;
	align-items: center;
	font-size: 2.5rem;
}
/*
.child.active:nth-child(1) {
	margin-left: -50px;
	margin-top: -50px;
}
.child.active:nth-child(2) {
	border-left: var(--bs-success) dotted 1px !important;
	border-right: var(--bs-success) dotted 1px !important;
	margin-top: -50px;
}
.child.active:nth-child(3) {
	margin-right: -50px;
	margin-top: -50px;
}
.child.active:nth-child(4) {
	border-bottom: var(--bs-success) dotted 1px !important;
	border-top: var(--bs-success) dotted 1px !important;
	margin-left: -50px;
}
.child.active:nth-child(6) {
	border-bottom: var(--bs-success) dotted 1px !important;
	border-top: var(--bs-success) dotted 1px !important;
	margin-right: -50px;
}
.child.active:nth-child(7) {
	margin-bottom: -50px;
	margin-left: -50px;
}
.child.active:nth-child(8) {
	border-left: var(--bs-success) dotted 1px !important;
	border-right: var(--bs-success) dotted 1px !important;
	margin-bottom: -50px;
}
.child.active:nth-child(9) {
	margin-bottom: -50px;
	margin-right: -50px;
}*/
.grid {
	display: grid;
	grid-template-columns: 0px auto 0px;
	grid-template-rows: 0px auto 0px;
}
.grid-start {
	position: absolute;
}
.grid-start.active {
	z-index: 999;
}
.grid-start-mobile {
	height: 56px;
	left: 50%;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 87px;
}
.map {
	border-bottom-left-radius: var(--pki-border-radius);
	border-bottom-right-radius: var(--pki-border-radius);
	height: 550px;
	position: relative;
	width: 100% !important;
	z-index: 0;
}
.page-container {
	position: relative;
}
.page-image {
	border-radius: 5px;
	width: 100%;
}
.page-image:not(:first-child) {
	margin-top: 10px;
}
.page-image-mobile {
	box-shadow: 2px 3px 5px 0px rgb(0 0 0 / 16%);
	width: 100%;
}
.page-image-view {
	width: 100%;
	-webkit-box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.16);
	-moz-box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.16);
	box-shadow: 2px 3px 5px 0px rgba(0,0,0,0.16);
}
.page-container:not(:first-of-type) {
	margin-top: 10px;
}
.page-container:last-of-type {
	margin-bottom: 10px;
}
pan-zoom {
	height: 100% !important;
	width: 100% !important;
}
.panzoom-div {
	height: 550px !important;
	pointer-events: none !important;
	position: absolute !important;
	top: 0px !important;
	width: 100% !important;
}
.panzoom-listgroup {
	height: 100vh !important;
	pointer-events: none !important;
	position: fixed !important;
	width: 100% !important;
}
.panzoom-pagecontainer {
	left: 0px !important;
	position: absolute !important;
	top: 0px !important;
}
.pdf-preparation-dialog-content {
	max-height: 700px !important;
	overflow: auto !important; 
	padding: 0rem !important;
	text-align: center;
	width: 100%;
}
.pdf-preparation-dialog-content::-webkit-scrollbar{
	display: none;
}
.pdf-preparation-dialog-content.loading {
	align-items: center;
	display: flex;
}
.placeholder-field {
	align-items: center;
	display: flex;
	text-align: center !important;
	z-index: 1000;
}
.placeholder-field.disabled {
	cursor: not-allowed;
	background-color: var(--bs-primary) !important;
    color: var(--bs-primary-color) !important;
	opacity: 0.5 !important;
}
.placeholder-field.disabled span,
.current-placeholder-field.disabled span {
	cursor: not-allowed;
}
.placeholder-field span {
	color: var(--bs-white);
	font-size: var(--bs-body-font-size);
}
.current-placeholder-field {
	align-items: center;
	background-color: var(--successcolour) !important;
	color: var(--bs-white) !important;
	cursor: pointer;
	display: flex;
	text-align: center !important;
	z-index: 1000;
}
.current-placeholder-field.disabled {
	cursor: not-allowed !important;
	background-color: var(--successcolourplaceholder) !important;
    color: var(--bs-success-color) !important;
}
.current-placeholder-field:not(.disabled) span {
	cursor: pointer;
	font-size: var(--bs-body-font-size);
	text-decoration: underline;
}
.pointer-events-auto {
	pointer-events: auto;
}
.touch-auto {
	touch-action: auto pinch-zoom;
}
.xbrl-preparation-dialog-content {
	overflow: auto !important; 
	padding: 0rem !important;
	width: 100%;
}

#gridlineHorizontalTop{
	position:absolute;
	border-bottom:1px dashed var(--bs-success); 
	height:1px; 
	top:0px; 
	margin-top:0px;
}

#gridlineHorizontalBottom{
	position:absolute;
	border-top:1px dashed var(--bs-success);
	height:1px;
}

#gridlineVerticalLeft{
	position:absolute;
	border-right:1px dashed var(--bs-success); 
	width:1px;
}

#gridlineVerticalRight{
	position:absolute;
	border-left:1px dashed var(--bs-success); 
	width:1px;
}