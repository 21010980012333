.badge {
    font-size: 14px;
    background: var(--bs-body-bg);
    font-weight: 400;
}
.badge-small {
    font-size: 10px !important;
}
.badge-listitem {
    float: left;
    font-size: 4px;
    height: 10px !important;
    margin-top: 6px;
    margin-right: 4px;
    width: 10px !important;
}

.badge-status {
    font-size: 4px;
    height: 10px !important;
    margin-right: 4px;
    vertical-align: middle !important;
    width: 10px !important;
}
.badge-status-table div.badge-listitem {
    margin-top: 2px !important;
}
.badge-status-table-mobile div.badge-listitem,
.badge-status-table-log-mobile div.badge-listitem {
    font-size: 2px;
    margin-top: 10px !important;
    height: 5px !important;
    width: 5px !important;
}
.badge-status-table-log-mobile div.badge-listitem {
    margin-top: 5px !important;
}

// "Blue" info Badge (Colours can vary, but as of writing)
.badge-style-info {
    background: var(--bs-info) !important;
}

// "Green" success Badge (Colours can vary, but as of writing)
.badge-style-success {
    background: var(--bs-success) !important;
}

// "Yellow" warning Badge (Colours can vary, but as of writing)
.badge-style-warning {
    background: var(--bs-warning) !important;
}

// "Red" Danger Badge (Colours can vary, but as of writing)
.badge-style-danger {
    background: var(--bs-danger) !important;
}
// "Grey" Secondary Badge (Colours can vary, but as of writing)
.badge-style-secondary {
    background: var(--bs-secondary) !important;
}






// at time of writing: these are the "Primary" == Blue orbs
// CREATED - Aangemaakt
.badge-state-created {
    background: var(--bs-primary) !important;
}

// NEW - Nieuw
.badge-state-new,
// ACTIVE - Actief
.badge-state-active {
    background: var(--bs-primary) !important;
}

// TASK - taak?
.badge-state-task {
    background: var(--bs-primary) !important;
}

// at time of writing: these are the "Succes" == Green orbs
// COMPLETED - Afgerond/Voltooid
.badge-state-completed {
    background: var(--bs-success) !important;
}

// at time of writing: these are the "Danger" == Red orbs
// DECLINED - Afgewezen
.badge-state-declined {
    background: var(--bs-danger) !important;
}

// EXPIRED - Verlopen
.badge-state-expired {
    background: var(--bs-danger) !important;
}

// ERROR - Fout
.badge-state-error {
    background: var(--bs-danger) !important;
}

// WITHDRAWN - Ingetrokken
.badge-state-withdrawn {
    background: var(--bs-danger) !important;
}
// DELETED - Verwijderd
.badge-state-deleted,
// Invitation Expired - Uitnodiging is verlopen
.badge-state-invitationexpired {
    background: var(--bs-danger) !important;
}


// at time of writing: these are the "Warning" == Orange orbs
// DELIVERING - Aan het afleveren/Afleveren(?)
.badge-state-delivering {
    background: var(--bs-warning) !important;
}


// Send - Versturen(?) 
.badge-state-send {
    background: var(--bs-warning) !important;
}

// Sent - Verstuurd
.badge-state-sent,
// Pending X - Afwachten
.badge-state-pendingsignature,
.badge-state-pendingdownload,
.badge-state-pendingapproval,
.badge-state-processbydigipoort,
.badge-state-pendingdigipoort,
.badge-state-pendingsbrnexus,
// ??
.badge-state-filing {
    background: var(--bs-warning) !important;
}

// Getekend(?) = geel?
.badge-state-signed {
    background: var(--bs-warning) !important;
}

// Pillbadges

// "Blue" info pill badge
.text-bg-info {
    background: var(--bs-info) !important;
    color: var(--bs-info-color) !important;
}

// "Green" success pill badge
.text-bg-success {
    background: var(--bs-success) !important;
    color: var(--bs-success-color) !important;
}

// "Yellow" warning pill badge
.text-bg-warning {
    background: var(--bs-warning) !important;
    color: var(--bs-warning-color) !important;
}

// "Red" Danger pill badge
.text-bg-danger {
    background: var(--bs-danger) !important;
    color: var(--bs-danger-color) !important;
}
// "Grey" Secondary pill badge
.text-bg-secondary {
    background: var(--bs-secondary) !important;
    color: var(--bs-secondary-color) !important;
}

.pillbadge-status {
    font-size: 12px;
}
.pillbadge-status.disabled {
    opacity: 0.5 !important;
}