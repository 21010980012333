h1, h2, h3 {
	font-family: var(--bs-body-font-family) !important;
	font-size: var(--font-size-heading);
	font-weight: 600;
}
h2.title {
	line-height: 28px;
	max-width: max-content;
	padding: 15px 1.5rem 4px 1.5rem !important;
}
h2.status-title {
	line-height: 28px;
	max-width: max-content;
	padding: 15px 1.5rem 4px 0.7rem !important;
}
h2.element-title {
	line-height: 28px;
	max-width: max-content;
	padding: 0px 1.5rem 4px 0px !important;
}
h3.title {
	padding: 20px 1.5rem 4px 24px !important;
}
h3.summary {
	padding-bottom: 20px !important;
	margin-bottom: 0px;
}
h3.title-dashboard {
	padding-bottom: 4px;
	padding-top: 20px;
}
h6.active {
	font-weight: bold;
	font-size: 18px;
}

strong.title{
	line-height: 28px;
	max-width: max-content;
	display: block;
}
.bold{
	font-weight: 600;
}