.carousel-indicators {
	height: 3px !important;
	margin-top: 30px !important;
}
.carousel-indicators .active, .carousel-indicators button.active {
	background: #3D3D3D !important;
}
.carousel-indicators [data-bs-target] {
	background: #BBBBBB !important;
	border-bottom: 0px !important;
	border-top: 0px !important;
	height: 4px !important;
	opacity: 1 !important;
}
.list-group-item.dashboard {
	font-size: var(--font-size-listitem-dashboard) !important;
    vertical-align: middle !important;
}
div.item-row.dashboard {
    height: 40px !important;
}
.picsum-img-wrapper {
	margin-bottom: 66px;
}
.picsum-img-wrapper h3 {
	font-size: var(--font-size-heading);
	font-weight: 500;
	margin-top: 30px;
}
