input[type="checkbox"] {
	// accent-color: #ffffff !important;
}
.checkbox-disable:checked{
    // background-color: $checkbox-color;
    // border-color: $checkbox-color;
	opacity: .4;
}
.dialog .rejectTexterea,
.dialog-withdraw .textarea {
	border: 1px solid var(--pki-border-color);
	border-radius: var(--bs-modal-border-radius);
	font-size: var(--bs-body-font-size);
	height: 90px;
	line-height: 125%;
	outline-width: 0px;
	padding: 10px;
	width: 100%;
}
.form-check-input[type="checkbox"] {
	box-shadow: none;
	margin-right: 10px;
	margin-top: 0px;
}
.form-check-input[type="checkbox"].dashboard {
	margin-right: 5px !important;
}
.form-check {
	display: inline !important;
}
.form-control,
.form-select {
	font-size: var(--bs-body-font-size);
}
.form-check-input.checkbox,
.form-check-input.defaultheight,
.form-check-input.radio {
    height: 14px !important;
    width: 14px !important;
}
.form-check-input:disabled~.form-check-label,
.form-check-input[disabled]~.form-check-label,
.form-check-input:disabled {
    opacity: 0.5 !important;
}
.form-control, input.form-control, .form-select, select.form-select {
	border-radius: var(--pki-border-radius);
}
.form-control.is-invalid,
.was-validated .form-control:invalid,
.form-control.is-valid,
.was-validated .form-control:valid{
	background-image: none;
	padding-right: 2px !important;
}
.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus,
.form-control.is-valid:focus,
.was-validated .form-control:valid:focus{
	box-shadow: none;
}
.form-control.rendereddocumentsummary-summary-dropdown {
    border: none;
    border-top: 1px solid var(--pki-border-color);
    border-left: 1px solid var(--pki-border-color);
    border-bottom: 1px solid var(--pki-border-color);
}
.form-switch .form-check-input.filter {
	height: 24px;
	margin-left: 0px;
	margin-right: 4px;
	margin-top: -4px;
	width: 39px;
}
.form-switch .form-check-input {
	height: 24px !important;
	width: 39px;
	margin-top: 0.1em;
	margin-left:-1.7em;
}
.form-control.edit,
.input-group-text.deadline.edit {
	background: var(--pki-editdata-formfield) !important;
}
.input-group-text.deadline {
	background-color: var(--bs-body-bg) !important;
	border: none;
}
.input-group-text.deadline em {
	font-size: var(--font-size-information);
}
.input-group-text.disabled {
    background-color: #e9ecef;
}
.input-group-text.required {
    background-color: transparent;
    border: none;
}
.input-group-text.datetimepicker-icon {
    background-color: var(--bs-white);
	border-top-right-radius: var(--pki-border-radius) !important;
	border-bottom-right-radius: var(--pki-border-radius) !important;
	cursor: pointer;
}
.invalid-feedback {
    white-space: normal !important;
}
label,
.input-group-text {
    font-size: var(--bs-body-font-size);
}
.input-group .datetimepicker {
	cursor: pointer;
}
.input-group input.datetimepicker {
	border-top-left-radius: var(--pki-border-radius) !important;
	border-bottom-left-radius: var(--pki-border-radius) !important;
	border-top-right-radius: var(--pki-border-radius) !important;
	border-bottom-right-radius: var(--pki-border-radius) !important;
}
label.subheaderlist {
    font-size: var(--font-size-listitem) !important;
}
.phone-input {
	display: block
}
.phone-input > div {
	width: 100%;
}
input.subheader, input.dropdown-group-left
select.subheader, select.dropdown-group-left,
button.subheader, button.dropdown-group-left,
.form-control.subheader, .form-control.dropdown-group-left {
	border: 1px solid var(--bs-gray-400);
	border-radius: var(--pki-border-radius);
	box-shadow: none;
	color: var(--fontdefaultcolour);
	font-size: var(--bs-body-font-size);
	text-decoration: none;
}
.dropdown-group-left, .form-control.dropdown-group-left, .form-control:disabled.dropdown-group-left {
	border-top-right-radius: 0px !important;
	border-bottom-right-radius: 0px !important;
}

/* This is needed for the right button in the Edit Document modal, it has to be this specific. */
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback).border-0 {
	border: none !important;
}

.dropdown-group-right {
	border-top-left-radius: 0px !important;
	border-bottom-left-radius: 0px !important;
}
textarea {
	resize: none;
}
input::placeholder,
textarea::placeholder,
.form-control:disabled {
	color: var(--pki-font-placeholder-color) !important;
}
.smallerform {
	font-size: var(--font-size-listitem-form) !important;
}

/* Filters */
.option-item {
	display: block !important;
	margin-right: 15px !important;
	padding: 12px 10px 12px 26px;
	width: max-content;
}
.option-item-small {
	display: block !important;
	padding: 6px 10px 6px 26px;
	width: max-content;
}
.option-item-small:hover:not(.title, .divider) {
    background-color: var(--primaryhovercolour) !important;
    border-color: var(--primaryhovercolour) !important;
    color: var(--primaryfontcolour) !important;
    width: max-content;
}
.option-item-small input[type="checkbox"] {
	margin-right: 8px;
	margin-top: 2px;
}
.option-item-small.title {
	color: var(--bs-gray-600);
}