.authenticatorSharedKey {
	display: block !important;
	width: 140px !important;
	margin: 0 auto !important; 
}
div.active-item {
	font-weight: bold !important;
}
div.inactive-item {
	color: #343A4080 !important;
}
span.badge.border-radius-profile {
	border-radius: 2px;
}
qrcode > div > img {
    display: block !important;
    margin: 0 auto !important;
}

/* Settings > Application > Stationery */
.browse-button {
    border: 1px solid var(--pki-border-color) !important;
}
.previewBackground {
    border-radius: 0.375rem !important;
}
.stationeryInputField {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
}

/* Settings > Organisation > Credits */
#ActionReminder {
    margin-top: -10px;
}

/* Settings > Organisation > Branding > Style */
input[type="color"].picker {
    width: 0px !important;
    height: 0px !important;
    border: 0px !important;
    border-width: 0px !important;
    padding: 0px !important;
    margin-top: 3px !important;
    visibility: hidden;
}
input[type="text"].form-control.hex {
    color: var(--bs-secondary);
    border-radius: 5px 0px 0px 5px !important;
}
.branding-button {
    background: #E9ECEF !important;
    border: 1px solid var(--pki-border-color);
    font-size: var(--bs-body-font-size);
}
.colorpicker-button {
    width: 55px;
}
.default-button {
    background: #E9ECEF;
    border: 1px solid var(--pki-border-color);
    font-size: var(--bs-body-font-size);
}
.dropdown-menu-add-user-workgroup {
	width: 60%;
}
.placeholder-text {
    opacity: 0.5 !important;
}

.image-background {
    border-radius: var(--pki-button-border-radius) !important;
    background:url("/assets/brandingImageBackground.png") !important;
    background-repeat:no-repeat;
    width: 100%;
    height: 100%;
    display: flex;
}

.brandingImageInfo{ 
    min-height: 100px;
}

.brandingImage {
    margin: auto;
    max-width: 100%;
    max-height: 150px;
}

#favicon {
    max-height: 130px;
}

#stampImage {
    padding-left: 50px;
    padding-top: 10px;
    position: absolute;
}

.allowedExtensionsInput{
    min-height: 92px;
}
.bootstrap.ng2-tag-input {
    border-bottom: none !important;
}
tag {
    line-height: 28px !important;
    padding: 0px 5px !important;
    background-color: var(--bs-primary) !important;
    color: var(--bs-primary-color) !important;
}
.tag-wrapper.ng-star-inserted {
    padding: 0px 8px 2px !important; 
}
delete-icon {
    height: 16px !important;
    margin-top: -2px !important;
}
.bootstrap svg{
    height: 26px;
}

.bootstrap tag {
    height: 30px !important;
    /* UI Properties */
    background: #007BFF 0% 0% no-repeat padding-box !important;
    border-radius: 30px !important;
    opacity: 0.5 !important;
}
.bootstrap tag:has(delete-icon) {
    opacity: 1.0 !important;
}