html, body {
	height: 100%;
	margin: 0;
}
body {
	font-family: var(--bs-body-font-family) !important;
	font-weight: 500 !important;
	color: var(--fontdarkcolour);
}
.cursor-pointer {
	cursor: pointer !important;
}
.cursor-change-order {
	cursor: ns-resize !important;
}
img {
	pointer-events: none;
	user-select: none;
}
hr {
	margin: 0px;
}
.default-boxshadow {
	box-shadow: 0px 3px 6px #00000029;
}
.default-boxrounded {
	border-radius: var(--pki-border-radius) !important;
}
.default-top-boxrounded {
	border-radius: var(--pki-border-radius) var(--pki-border-radius) 0 0 !important;
}
.default-bottom-boxrounded {
	border-radius: 0 0 var(--pki-border-radius) var(--pki-border-radius) !important;
}
.information {
	font-size: var(--font-size-information) !important;
}
i.information {
	margin: 0px 5px 0px 14px; 
}
.no-radius-bottom-left {
	border-bottom-left-radius: 0px !important;
}
.no-radius-bottom-right {
	border-bottom-right-radius: 0px !important;
}
.no-border-top {
	border-top: none !important;
}
.no-radius-top-left {
	border-top-left-radius: 0px !important;
}
.no-radius-top-right {
	border-top-right-radius: 0px !important;
}
.content-temp {
	position: relative;
	top: 100px;
}
.vh-100 {
	height: calc(100vh - 161px) !important;
}
.top--20 {
	top:-20px;
}

.completed-z-index {
	z-index:2002;
}

/* Errors */
ngb-alert.workgroup-modal-information {
	background:rgba(0, 123, 255, 0.1);
	border-color: rgba(0, 123, 255, 0.5);
	padding: 10px;
}
ngb-alert.workgroup-modal-information > a, .modal-footer > a {
	color: var(--bs-primary);
	text-decoration: none;
}
#alert_link {
	color: var(--bs-white) !important;
	font-style: italic  !important;
	text-decoration: underline !important;
}
div.error {
	margin: 2px 0px 2px 12px;
}
div.error .alert {
	padding: 4px 8px !important;
}

/* Icons */
.bi-large {
	font-size: large;
}
li .iconbox {
	display: none;
}
li:hover .iconbox {
	border: 0px !important;
	display: inline-table;
	margin: 0px !important;
	padding: 0px !important;
}
.bi-0-circle-fill, .bi-1-circle-fill, .bi-2-circle-fill, .bi-3-circle-fill, .bi-4-circle-fill, .bi-5-circle-fill, .bi-6-circle-fill, .bi-7-circle-fill, .bi-8-circle-fill, .bi-9-circle-fill,
.bi-circle-fill {
	font-size: 16px !important;
	margin: 0px 8px 0px 0px;
}
.bi-exclamation-circle-fill {
	font-size: 15px !important;
}

/* Rendering */
.pageholder>table {
	width: 100%;
}

/* Tooltips */
.tooltip-box {
	margin-bottom: 3px !important;
}
.tooltip-box .tooltip-inner {
	background-color: var(--bs-white);
	-webkit-box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
	-moz-box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
	box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
	border: 1px solid var(--bs-gray-200);
	color: var(--bs-black);
	font-size: var(--font-size-information);
	max-width: 258px;
	padding: 8px 5px 5px 8px;
}
.tooltip-box.bs-tooltip-end .tooltip-arrow::before {
	border-right-color: var(--bs-white);
}
.tooltip-box.bs-tooltip-start .tooltip-arrow::before {
	border-left-color: var(--bs-white);
}
.tooltip-box.bs-tooltip-top .tooltip-arrow::before {
	border-top-color: var(--bs-white);
}
.tooltip-box.bs-tooltip-bottom .tooltip-arrow::before {
	border-bottom-color: var(--bs-white);
}
.tooltip-profile .tooltip-inner {
	max-width: 100% !important;
}

/* Scrollbars */
ul.cloudlocationslist {
    border: 0px;
    max-height: 400px;
    overflow-y: auto;
    padding-right: 9px;
    width: 100%;
}
ul.cloudlocationslist:focus, ul.cloudlocationslist:focus-visible {
    border: 0px !important;
    outline: none;
}
ul.cloudlocationslist::-webkit-scrollbar {
    background: #E9ECEF 0% 0% no-repeat padding-box;
    border-radius: var(--pki-border-radius) !important;
	cursor: hand !important;
    width: 9px !important;
    z-index: 1000;
}
ul.cloudlocationslist::-webkit-scrollbar-thumb {
    background-color: var(--bs-secondary);
    border-radius: var(--pki-border-radius) !important;
    cursor: hand !important;
}
ul.cloudlocationslist::-webkit-scrollbar-track {
    cursor: hand !important;
}
.checkboxdisabled{
	opacity: 50% !important;
}

/* Animations */
.shake {
	animation: shakebox 0.2s infinite;
	-webkit-animation: shakebox 0.2s infinite;
	animation-duration: 0.2s;
    -moz-animation-duration: 0.2s;
	animation-name: shakebox;
    -moz-animation-name: shakebox;
	animation-iteration-count: infinite;
	-moz-animation-iteration-count: infinite;
	transform: rotate(-0.5deg);
	-moz-transform: rotate(-0.5deg);
	-webkit-transform: rotate(-0.5deg);
}
.stop-animation {
	animation: none !important;
}
@keyframes shakebox {
    0% { transform: rotate(-0.5deg); }
	50% { transform: rotate(0.5deg); }
}
@-webkit-keyframes shakebox {
    0% { -webkit-transform: rotate(-0.5deg); }
	50% { -webkit-transform: rotate(0.5deg); }
}
@-moz-keyframes shakebox {
    0% { -moz-transform: rotate(-0.5deg); }
	50% { -moz-transform: rotate(0.5deg); }
}
#jsd-widget {
	display:none;
}


@media only screen and (min-height: 927px) {

    .force-min-height {
        height: calc(90vh);
    }
}

@media only screen and (max-height: 926px) {

    .force-min-height {
        height: 100%
    }
}

.standard-container-width {
    width: 318px;
}

.white-space, .list-group-item div > .white-space {
    white-space:pre-wrap;
	line-height: 14px !important; 
}
.video-container {
	position: relative !important;
	overflow: hidden !important;
	width: 100% !important;
	padding-top: 56.25% !important;
}
iframe.responsive-iframe {
	position: absolute !important;
	overflow-y: hidden !important;
	top: 0 !important;
	left: 0 !important;
	bottom: 0 !important;
	right: 0 !important;
	width: 100% !important;
	height: 100% !important;
}

// "Blue" info Badge (Colours can vary, but as of writing)
.font-style-info {
    color: var(--bs-info) !important;
}

// "Green" success Badge (Colours can vary, but as of writing)
.font-style-success {
    color: var(--bs-success) !important;
}

// "Yellow" warning Badge (Colours can vary, but as of writing)
.font-style-warning {
    color: var(--bs-warning) !important;
}

// "Red" Danger Badge (Colours can vary, but as of writing)
.font-style-danger {
    color: var(--bs-danger) !important;
}
// "Grey" Secondary Badge (Colours can vary, but as of writing)
.font-style-secondary {
    color: var(--bs-secondary) !important;
}