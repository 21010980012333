/* You can add global styles to this file, and also import other style files */
@import "fonts.scss";

@import "~bootstrap/dist/css/bootstrap.css";
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "./assets/css/general.scss";
@import "./assets/css/app.scss";
@import "./assets/css/badges.scss";
@import "./assets/css/buttons.scss";
@import "./assets/css/dropdowns.scss";
@import "./assets/css/forms.scss";
@import "./assets/css/headings.scss";
@import "./assets/css/lists.scss";
@import "./assets/css/modals.scss";
@import "./assets/css/navigation.scss";
@import "./assets/css/pagination.scss";
@import "./assets/css/progressbars.scss";
@import "./assets/css/toasts.scss";
@import "./assets/css/specific_dashboard.scss";
@import "./assets/css/specific_builder.scss";
@import "./assets/css/specific_placeholder.scss";
@import "./assets/css/specific_settings.scss";
@import "./assets/css/specific_status.scss";
@import "./assets/css/specific_viewer.scss";
@import "./assets/css/specific_requeststatus.scss";
@import "variables.scss";

@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";

:root {
	--bs-body-font-size: 14px;
	--font-size-heading: 16px;
	--font-size-alert: 12px;
	--font-size-toast-time: 12px;
	--font-size-listitem: 12px;
	--font-size-listitem-form: 12px;
	--font-size-listitem-mobile: 12px;
	--font-size-listitem-cloudlocations: 12px;
	--font-size-listitem-dashboard: 12px;
	--font-size-listitem-heading: 15px;
	--font-size-information: 12px;
	--font-size-listitem-emailactivity-mobile: 10px;
	--font-size-icon: 13px;
	--bs-font-base-color: rgb(19, 21, 35);
	--bs-body-font-family: "Poppins", sans-serif;
	--bs-body-line-height: 1.3;
	--pki-border-radius: 5px;
	--pki-border-color: #CED4DA;
	--pki-button-border-radius: 5px;
	--pki-editdata-formfield: rgba(19, 21, 35, 0.10);
	--pki-emailpreviewdialog-linktext: rgba(19, 21, 35, 0.50);
	--pki-emailpreviewdialog-linkurl: rgb(29, 135, 253);
	--pki-font-placeholder-color: rgba(19, 21, 35, 0.50);
	--pki-uploadbox-iconcolor: #B0B6BA;
	--pki-uploadbox-backgroundcolor: #13152310;
}
.identification-card #ngb-nav-2-panel {
	height: 200px !important;
	width: 426px;
}
.identification-card #ngb-nav-2-panel > img {
	height: 90%;
	left: 50%;
	position: absolute;
	top: 50%;
    transform: translate(-50%, -25%);
}
.default-font-size {
	font-size: var(--bs-body-font-size) !important;
}
.table-alert-box-font-size {
    font-size: var(--font-size-listitem);
}
.btn, a, a.page-link > span, a.nav-link > h6, a.nav-link > span, .switch-form-default, .option-item-small, .option-item-small > input, .option-item-small > label, .form-check.form-switch > label, li.list-group-item.requestStatusActionsListItem.hasEmailActivity, #workgroupSelector, #workgroupSelector > option {
	cursor: pointer;
}
ul.list-group > li > div, .workflowPlaceholder, li.list-group-item, h1, h2, h3, h4, h5, h6, span, .option-item-small.title, li.list-group-item.requestStatusActionsListItem, .activityItem, .stationery-list-item  {
	cursor: default;
}
.ng-draggable, .ng-draggable > .placeholder-field.child.active > span {
	cursor: grab !important;
}  
.ng-dragging, .ng-dragging > .placeholder-field.child.active > span {
	cursor: grabbing !important;
}
button#dropdownMenu, button > span {
	cursor: pointer;
}
button#dropdownMenu.breakingRecoveryCodesButton {
	cursor: text;
}
.fs-12 {
	font-size: var(--font-size-listitem) !important;
}
.base-font-color {
	color: var(--bs-font-base-color) !important;
}
.lato-font {
    font-family: 'Lato', sans-serif;
    font-style: normal;
}
.mobile-text {
    font-size: var(--font-size-listitem-mobile);
}

/* Custom owl-date-time styling */
owl-date-time {
	display: none !important;
}
button.owl-dt-control {
	color: var(--bs-primary) !important; 
	font-size: var(--bs-body-font-size) !important;
}