app-toasts {
	position: fixed;
	right: 0px;
	z-index: 9999;
}
@media only screen and (max-width: 991px) {
	app-toasts {
		bottom: 20px;
	}	
}
@media only screen and (min-width: 992px) {
	app-toasts {
		bottom: 80px;
	}
}
.toast {
	--bs-toast-bg: rgba(var(--bs-body-bg-rgb), 1.0) !important;
}
.toast:not(:last-child) {
    margin-bottom: 10px;
}
.toast-header-image {
	border-radius: 5px;
	margin-right: 3px;
	padding: 10px;
}
.toast-header-text {
	margin-right: auto;
	color: var(--fontdefaultcolour);
}
.toast-header {
	border-bottom: 0 !important;
}
.toast-message {
	z-index: 3000;
}
.toast-time {
	color: var(--bs-gray-400);
	font-size: var(--font-size-toast-time);
}
.toast-time-container {
	text-align: left;
	max-width: 100%;
}
.hide-close-button > .toast-header > .btn-close {
	visibility: hidden;
}

:not(.hide-close-button) > .toast-header > .btn-close {
	z-index: 9999;
}
