.addSignatureFieldModal > .modal-dialog {
	max-width: none;
}
.addSignatureFieldModal > .modal-dialog > .modal-content, .renderedModal > .modal-dialog > .modal-content{
	background-color: transparent;
	border: none !important;
	margin-top: 25px;
}
.addSignatureFieldModalMobile > .modal-dialog > .modal-content{
	background-color: transparent;
}
.completedModal {
	z-index: 2001;
}
.dialog {
	padding: 20px;
}
.dialog-bg-white .modal-content {
    background: var(--bs-white) !important;
}
.dialog-footer-height {
	height: 70px;
}
.modal-button-close.close,
.modal-button-close.close:hover {
	background: none !important;
	border: none !important;
}
.modal-backdrop {
	--bs-backdrop-opacity: 0.75 !important;
}
.modal-body.email-preview-dialog a {
	cursor: pointer !important;
}
.modal-body.pdf-preparation-dialog-content {
	height: 100vh !important;
    overflow-y: hidden;
}
.modal-body.pdf-preparation-dialog-content.mobile {
	background: transparent;
	margin-bottom: -38px;
	max-height: 80vh !important;
	min-height: 550px !important;
    overflow-y: hidden;
}
.modal-body {
	-webkit-overflow-scrolling: touch !important;
}
.modal-body:not(.modal-allow-overflow) {
	// Needed to make the modal scrollable on iOS.
	overflow: auto !important;
}
.modal-content {
	border: none !important;
	border-radius: var(--pki-border-radius) !important;
}
.min-height-modal-forcepermissions {
	height: 128px !important;
}
.modal-dialog {
	touch-action: none !important;
}
.modal-footer {
	border-top: none !important;
}
.modal-footer > a {
	left: 20px;
	position: absolute;
	text-decoration: none;
}
.modal-footer i {
	margin-right: 5px;
}
.modal-footer--sticky {
	bottom: 0;
	left: 0px;
	position: sticky;
	z-index: 1055;
}
.modal-footer.border-top-active {
    border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color) !important;
}
.modal-editdocument {
    min-width: 536px !important;
}
.overlay-container {
	max-width: 802px !important;
	height: 315px;
}
.overlay-fullscreen .modal-fullscreen > .modal-content {
	background-color: var(--platformbackgroundcolour) !important;
	border: none !important;
	border-radius: 0px !important;
}
.performActionSummaryModal {
	top: 100px;
}
.pkiSm { /* 100% */
	width: 550px !important;
}
.pkiMd { /* 150% */
	width: 825px !important;
}
.pkiLg { /* 200% */
	width: 1100px !important;
}
.pkiXl { /* 250% */
	width: 1375px !important;
}
.rss-dialog {
	height: 450px;
	outline: none !important;
	width: 100%;
}
.rss-dialog-inner {
	height: 420px;
	outline: none !important;
	width: 100%;
}
.renderedData figure img {
	max-width: 100% !important;
	height: 100%;
}
span.link-text {
	color: var(--pki-emailpreviewdialog-linktext);
}
span.link-url {
	color: var(--pki-emailpreviewdialog-linkurl);
}

.modal-font{
	font-size: var(--bs-body-font-size);
}
.dashed-line-bottom {
	width: 95%;
	margin: auto;
	border-bottom: 1px black dashed;
}
.overlay-mobileviewport {
	background-color: rgba(0, 0, 0, 0.75);
	height: 100%;
	overflow: hidden;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 9000 !important;	
	-webkit-backdrop-filter: blur(25px) !important;
    -moz-backdrop-filter: blur(25px) !important;
    -o-backdrop-filter: blur(25px) !important;
    -ms-backdrop-filter: blur(25px) !important;
    backdrop-filter: blur(25px) !important;
}
.overlay-mobileviewport-modal {
	margin: auto !important;
	max-width: 400px;
	padding: 10px;
	padding-top: 150px;
	z-index: 9001 !important;
}