.activity-item-progress-bar {
	border-radius: 0px !important;
	height: 3px;
	margin-top: 6px;
}
.document-progressbar {
	margin: 0px -10px 0px 10px !important;
	padding-right: 10px;
}
.item-progress-bar {
	border-radius: 0px !important;
	height: 4px;
}
.progress.dashboard {
	height: 10px;
}
span.progress {
	margin-top: 6px;
}
.bg-processing.progress-bar {
	background-color: #E7E7E7 !important;
}
