.btn.rendereddocumentsummary-summary-dropdown {
    border: 1px solid var(--pki-border-color);
	text-align: left;
	width: 100%;	
}

button#dropdownMenu.buttonrequired, input.form-control.inputrequired {
	border: 1px solid var(--bs-primary) !important;
}
button#dropdownFilterMenu.filter, button#dropdownMenu.filter, 
button#dropdownMenu.workgroup, button#dropdownMenu.requestWorkgroup,
button#dropdownMenu.default {
    background: var(--bs-white);
	border: none;
	border-radius: var(--pki-border-radius);
	box-shadow: none;
	color: var(--fontdefaultcolour);
	display: block;
	font-size: var(--bs-body-font-size);
	font-weight: 400;
	height: 33px;
	text-align: left;
    width: 100%;
}
button#dropdownMenu.default:not(.breakingRecoveryCodesButton) > span:not(.breakingRecoveryCodes) {
	cursor: pointer;
}
button#dropdownMenu.language,
button#dropdownMenu.authenticatorSecret,
button#dropdownMenu.recoveryCodes {
	background: var(--bs-white);
	border: 1px solid var(--bs-gray-400) !important;
	border-radius: var(--pki-border-radius);
	color: var(--fontdefaultcolour);
	display: block;
	font-weight: 400;
	height: 38px;
	text-align: left;
    width: 100%;
}
button#dropdownMenu.language:focus {
	border: 1px solid var(--fontdefaultcolour) !important;
	box-shadow: none;
	outline: none !important;
}
button#dropdownMenu.authenticatorSecret,
button#dropdownMenu.recoveryCodes {
	background: #EAECEF 0% 0% no-repeat padding-box !important;
}
button#dropdownMenu.filter.dropdown-toggle::after,
button#dropdownFilterMenu.filter.dropdown-toggle::after,
button#dropdownMenu.workgroup.dropdown-toggle::after,
button#dropdownMenu.requestWorkgroup.dropdown-toggle::after,
button#dropdownMenu.language.dropdown-toggle::after,
button#dropdownMenu.default.dropdown-toggle::after {
	display: none;
}
button#dropdownMenu.filter:hover, button#dropdownMenu.filter:active, button#dropdownMenu.filter:visited,
button#dropdownFilterMenu.filter:hover, button#dropdownFilterMenu.filter:active, button#dropdownFilterMenu.filter:visited,
button#dropdownMenu.requestWorkgroup:hover {
	background: var(--bs-white) !important;
}
button#dropdownMenu.workgroup,
button#dropdownMenu.default {
	border: 1px solid var(--bs-gray-400) !important;
}
.dashboard-filter-dropdown {
	width: auto !important;
}
.dropdownMenuTime {
	height: 400px; 
	overflow-x: hidden;
	overflow-y: auto; 
}
.btn-group .dropdown {
	border-width: 1px 1px 1px 0px !important;
	border-style: solid;
	border-color: #dee2e6 !important;
}
.dropdown-item {
	margin-right: 15px !important;
	padding: 9px 19px 7px 24px;
}
.dropdown-item:focus, .dropdown-item:hover,
button.dropdown-item:focus, button.dropdown-item:hover {
	// background: $button-navigation-default-color !important;
	// color: $button-navigation-font-color !important;
}
.dropdown-item.active, .dropdown-item:active,
button.dropdown-item.active, button.dropdown-item:active {
	color: var(--bs-white) !important;
	// background: $button-navigation-active-color !important;
}
.dropdown-menu {
	border: none;
	box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
	float: right;
	font-size: var(--bs-body-font-size);
	margin-top: -2px;
}
.dropdown-menu.mobile {
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
	line-height: 125%;
	margin-top: 11px;
	padding: 20px;
	right: 0px;
}
.dropdown-menu.show.filterMenu {
	max-height: 300px;
	overflow-y: auto;
}
.dropdown-menu-pdf-preparation-dialog { 
	max-height: 500px;
	min-width: 100% !important; 
	overflow-x: hidden;
	overflow-y: auto;
}
.dropdown-menu-person-dialog {
	max-height: 500px;
	overflow-x: hidden;
	overflow-y: auto;
}
.dropdown-toggle {
	font-size: var(--bs-body-font-size) !important;
}
a.dropdown-toggle::after {
	position: relative;
	top: 3px;
	margin-left: 10px;
	font-size: 18px;
}
.dropdown-toggle::after {
	position: relative;
	top: 3px;
	margin-left: 10px;
	margin-right: 5px;
	font-size: 18px;
}
.dropdown-toggle:not(.btn)::after {
	border-bottom: 0;
	border-left: 0.3em solid transparent;
	border-right: 0.3em solid transparent;
	border-top: 0.3em solid;
	content: "";
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
}
.dropdown-toggle:not(.btn):not(.iti__selected-flag), .dropdown-toggle-add-files, .dropdown-toggle-document-options, .dropdown-toggle-person-options {
	font-size: var(--bs-body-font-size) !important;
	font-weight: 600;
	height: 40px;
	width: 156px;
}
.dropdown-toggle-add-files {
	width: 200px;
}
.dropdown-toggle-document-options, .dropdown-toggle-person-options, .button-person-add, .button-actions-add {
	height: 33px;
	width: 100%;
}
.filterMenu .dropdown-item-header,
.filterMenu .dropdown-item-header:hover {
	background-color: transparent !important;
	color: rgba(19, 21, 35, 0.50) !important;
	cursor: default;
}
.rendereddocumentsummary-summary-dropdown:focus {
    border: 3px solid #A6CBF3;
}

.height33px{
	height: 33px !important;
}

@media only screen and (max-width: 1199px) {
	button#dropdownMenu.actions  {
		font-size: var(--font-size-listitem-mobile) !important;
		border: 0 !important;
	}

	.actions-dropdown{
		font-size: var(--font-size-listitem-mobile) !important;
	}
}