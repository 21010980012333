@media only screen and (max-width: 991px) {
	.actionzone {
		min-height: 151px !important;
		padding: 0px 0px 10px 0px;
	}
	.countzones {
		margin: 0 auto !important;
	}
	.dropzone-documents,
	.zone {
		border: 1px solid var(--pki-border-color);
		height: 91px !important;
	}
	.zone-noitem-hide {
		display: none;
	}
}
@media only screen and (min-width: 992px) {
	.actionzone {
		min-height: 156px !important;
		padding: 50px 0px 50px 0px;
	}
	.dropzone-documents {
		border: 1px dashed var(--pki-border-color) !important;
	}
	.dropzone-documents,
	.zone {
		min-height: 156px !important;
	}
	.zone-noitem {
		height: 350px !important;
	}
}
.dropzone-documents,
.zone,
.actionzone {
    background: var(--pki-uploadbox-backgroundcolor) 0% 0% no-repeat padding-box;
    border-radius: var(--pki-border-radius) !important;
    opacity: 1;
}
.actionzone, .zone {
    border: 1px solid var(--pki-border-color);
	margin: 25px 0px 0px 0px;
}
#accordion .accordion-item  h3{
	font-weight: normal !important;
}
#accordion .accordion-item h3.active {
	font-weight: bold !important;
}
.builder-navigation {
	padding: 15px 1.5rem 4px 1.5rem !important;
}
.builder-navigation nav a{
	width: 25% !important;
}
.builder-navigation .nav-link {
	padding-right: 0px;
}
.builder-navigation .nav-link:first-child {
	padding-left: 0px !important;
}
.builder-navigation .nav-link.disabled > h6,
.builder-navigation .nav-link.disabled > i {
	color: var(--bs-gray-500) !important;
}
.disabledDiv {
	pointer-events: none !important;
	opacity: 0.4 !important;
	color: var(--bs-nav-link-disabled-color) !important;
}
.disabled-item {
	pointer-events: none !important;
	opacity: 0.65 !important;
	color: var(--bs-nav-link-disabled-color) !important;
}
.person-row {
	height: 2rem;
}
.builder-step-block {
	height: 26px;
	cursor: pointer;
}

.builder-step-block > h1, .builder-step-block > h2, .builder-step-block > h3, .builder-step-block > h4, .builder-step-block > h5, .builder-step-block > h6 {
	cursor: pointer;
}
.requestBuilderHeaderLine {
	float: right;
	height: 0px !important;
	left: 130px !important;
	opacity: 1;
    position: sticky;
	top: 187px !important;
    width: calc(100% - 25px) !important;
}
.requestHeader {
	height: 87px;
	margin-left: 0px;
}
.request-id .copy-icon,
td .copy-icon,
span .copy-icon {
	display: none;
}
.request-id:hover .copy-icon,
td:hover .copy-icon,
span:hover .copy-icon {
	display: inline;
	margin-left: 7px;
}
.request-name {
	font-weight: 600;
	line-height: 14px;
	max-width: max-content;
	overflow: hidden;
  	text-overflow: ellipsis;
}
.actionIcon {
	color: var(--pki-uploadbox-iconcolor);
	display: block;
	font-size: 68px;
	margin-bottom: 20px;
}
.document-action-section {
	position: relative;
	width: 100%;
	min-height: 33px;
}
.document-action-wrapper {
	position: absolute;
	display: inline-block;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	right: 0;
	left: 0;
}
.document-action-wrapper-right {
	right: 122px;
}
.document-action div {
	width: 100% !important;
}
.document-action-button {
	position: absolute;
	display: inline-block;
	right: 0;
	margin: 0px;
	width: 120px !important;
	padding: 0px;
}
.edit-document-list {
	max-height: 358px;
	overflow-y: scroll;
}
.edit-document-list .page {
	float: left;
	font-size: var(--font-size-information) !important;
	display: grid;
}
.edit-document-list .page  .spinner-border, .edit-document-list .page  .page-preview  {
	grid-column: 1; 
	grid-row: 1;
}
.edit-document-list .page  .spinner-border {
	margin: auto;
	width: 60px;
	height: 60px;
	color: var(--bs-primary)
}
.edit-document-list input + label .page-preview {
    display: inline-block;
	max-width: 111px;
    border: 3px solid #E0E0E0;
	border-radius: 5px;
    cursor: pointer;
}
.edit-document-list input:checked + label .page-preview {
    border: 3px solid var(--bs-primary) !important;
}
.edit-document-list input:checked + label:hover .page-preview {
    border: 3px solid var(--primaryhovercolour) !important;
}
.edit-document-list input:hover + label .page-preview {
    border: 3px solid var(--bs-secondary) !important;
}
.edit-document-list input {
	display: none;
}
.edit-document-stationery-list {
	max-height: 400px;
	overflow-y: scroll;
}
.edit-document-stationery-list input,
.edit-document-stationery-list label {
	font-size: var(--font-size-information) !important;
}
.edit-document-stationery .page-preview {
    display: inline-block;
	max-width: 150px;
    border: 1px solid var(--bs-primary) !important;
	border-radius: 5px;
}


@media only screen and (min-width: 768px) {
	.breadcrumb-size{
		max-width: 80%;
	}
}
@media only screen and (min-width: 990px) {
	.breadcrumb-size{
		max-width: 85%;
	}
}
@media only screen and (min-width: 1260px) {
	.breadcrumb-size{
		max-width: 90%;
	}
}
@media only screen and (min-width: 1920px) {
	.breadcrumb-size{
		max-width: 93%;
	}
}

.cdk-drag-preview {
	@extend .list-group-item;
}

.nav-tabs {
    line-height: 14px;
}


/** Date time picker */
.owl-dt-calendar-table .owl-dt-calendar-cell-selected {
	background: var(--bs-primary) !important;
}