.row>* {
	padding-bottom: 2px;
	padding-right: 0px;
	padding-top: 2px;
}
.row {
	margin-right: 0px;
}
.request-status-table-header,
.request-status-title-table-header {
	background: var(--bs-light) !important;
}
.request-status-table-header {
	border-bottom: 0 !important;
	font-size: var(--font-size-heading) !important;
	height: 40px !important;
	padding-bottom: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.request-status-table-header a {
	font-size: var(--bs-body-font-size) !important;
}
.request-status-actor-row {
	height: 42px;
}
.request-status-activities-list {
	background: var(--bs-light) !important;
	border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
	border-radius: var(--bs-list-group-border-radius);
	font-size: var(--font-size-listitem) !important;
}
.activity-list-group-title {
	color: var(--bs-secondary);
	font-weight: 600;
	width: calc(100% - 17px);
}
.activity-list-group-items {
	max-height: 90px;
	overflow-y: scroll;
}
.sorting {
	display: inline-grid;
	line-height: 4px !important;
	margin-left: 4px;
	position: relative;
    top: -5px;
}
.sorting i {
	color: var(--bs-gray-500);
	float: left;
	font-size: 10px;
}
.sorting i:first-child {
	margin-bottom: -3px !important;
	padding: 0px !important;
}
.sorting.sorting-item {
	line-height: 4px !important;
}
.alert-higher-row {
	height: 32px !important;
}

/* List group */
.list-group-item,
.list-group-item input,
.list-group-item select,
.list-group-item-disabled,
.list-group-item-disabled input,
.list-group-item-disabled select {
	font-size: var(--font-size-listitem) !important;
}
.list-group-item div,
.list-group-item-disabled div {
	line-height: 24px !important;
}
.list-group-item:hover,
.list-group-item-disabled:hover {
	background: #F8F9FA 0% 0% no-repeat padding-box;
	opacity: 1;
}
.list-group-item.nohover {
	background: transparent !important;
	opacity: 1 !important;
}
.list-group-item-disabled.nohover{
	color: var(--pki-font-placeholder-color) !important;
}
.list-group-item-disabled input:not(.defaultheight), 
.list-group-item-disabled select, 
.list-group-item input:not(.defaultheight),
.list-group-item select:not(.colorMode) {
	height: 18px;
}
.list-group-item select.colorMode {
	width: 98px;
}
.list-group-item-disabled.subheader,
.list-group-item.subheader {
	font-weight: 500 !important;
}
.list-group-item.subheader {
	background: #F7F7F7;
}

.list-group .sub-list-header,
.list-group-item-disabled .sub-list-header {
	background: #F7F7F7;
	border: 1px solid #DFDFDF;
	border-radius: 5px 5px 0px 0px;
	font-size: var(--font-size-listitem-heading) !important;
}
.list-group-item-disabled.firstcol,
.list-group-item .firstcol {
	margin: 0px -10px 0px 10px !important;
}

/* If no signaturefield placed: */

/* Display place button with warning color */
.no-signaturefield-placed {
	border-color: var(--bs-warning) !important;
}
/* Set bottom border previous element 0px */
li.list-group-item:has(+ li.list-group-item.no-signaturefield-placed) {
	border-bottom-width: 0px !important;
}
/* Add warning border to current list item */
.list-group-item.no-signaturefield-placed {
	border-width: 2px !important;
}

/* List group horizontal */
ul.list-group-horizontal {
    height: 50px !important;
    justify-items: center !important;
    line-height: 16px !important;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    padding-bottom: 0px !important;
    padding-top: 0px !important;
}
ul.list-group-horizontal:first-child li:first-child {
    border-top-left-radius: 5px !important;
}
ul.list-group-horizontal:first-child li:last-child {
    border-top-right-radius: 5px !important;
}
ul.list-group-horizontal:last-child li:first-child {
	border-bottom-left-radius: 5px !important;
    border-top-left-radius: 0px !important;
}
ul.list-group-horizontal:last-child li:last-child {
    border-bottom-right-radius: 5px !important;
	border-top-right-radius: 0px !important;
}
ul.list-group-horizontal:not(:last-child) li {
    border-bottom: 0px !important;
    border-radius: 0px !important;
}

/* Accordion */
.accordion-button, .accordion-button:focus {
	border-color: rgba(0,0,0,.125);
    box-shadow: none;
}
.accordion-item:first-child, .accordion-list:first-child,
.accordion-item:first-of-type {
	border-bottom-left-radius: 0px !important;
	border-bottom-right-radius: 0px !important;
	border-top-left-radius: var(--pki-button-border-radius) !important;
	border-top-right-radius: var(--pki-button-border-radius) !important;
}
.accordion-item:last-child, .accordion-list:last-child,
.accordion-item:last-of-type {
	border-bottom-left-radius: var(--pki-button-border-radius) !important;
	border-bottom-right-radius: var(--pki-button-border-radius) !important;
	border-top-left-radius: 0px !important;
	border-top-right-radius: 0px !important;
}
.accordion {
	border-bottom-left-radius: var(--pki-button-border-radius) !important;
	border-bottom-right-radius: var(--pki-button-border-radius) !important;
	border-left: 1px solid var(--pki-button-border-radius) !important;
	border-right: 1px solid var(--pki-button-border-radius) !important;
	border-top-left-radius: var(--pki-button-border-radius) !important;
	border-top-right-radius: var(--pki-button-border-radius) !important;
}
.accordion-header {
    background-color: var(--bs-light) !important;
    border-top-left-radius: var(--pki-button-border-radius) !important;
	border-top-right-radius: var(--pki-button-border-radius) !important;
}
.accordion-collapse {
	border-bottom-left-radius: var(--pki-button-border-radius) !important;
	border-bottom-right-radius: var(--pki-button-border-radius) !important;
	border-top: 1px solid var(--tablecontentborder);
}

.list-group-item.cloudlocations {
	font-size: var(--font-size-listitem-cloudlocations) !important;
	line-height: var(--font-size-listitem-cloudlocations) !important;
}

@media only screen and (max-width: 1199px) {
	.list-group-item,
	.list-group-item input,
	.list-group-item select,
	.list-group-item-disabled,
	.list-group-item-disabled input,
	.list-group-item-disabled select {
		font-size: var(--font-size-listitem-mobile) !important;
		line-height: var(--font-size-listitem-mobile) !important;
	}
}