.header {
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 10;
}

.header-general {
    height: 43px !important;
}

.sub-header {
    -webkit-box-shadow: 2px 3px 5px 0px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: 2px 3px 5px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 2px 3px 5px 0px rgba(0, 0, 0, 0.16);
    height: 62px !important;
}

.header-general img {
    height: 33px;
}

a.header-user,
.header-user {
    border: 0px !important;
    font-size: var(--bs-body-font-size);
    font-weight: normal !important;
    text-decoration: none;
}

.app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.app-flex-container {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.app-content {
    flex: 1;
    padding-bottom: 45px;
}

.app-footer {
    position: relative;
    width: 100%;
    height: 45px;
    margin-top: auto; // Push the footer to the bottom
}

.footer,
.footer a {
    // color: $footer-font-default-color !important;
    text-decoration: none;
}