
@media only screen and (max-width: 767px) {
    .actions-emailactivity{
        font-size: var(--font-size-listitem-emailactivity-mobile) !important;
    }
    
    .list-group-item .actions-emailactivity.request-status-activities-list div, .list-group-item-disabled .actions-emailactivity.request-status-activities-list div {
        line-height: 10px !important;
    }
}

.statusHeader {
	border-radius: var(--pki-border-radius) !important;
	height: 76px;
	margin-left: 0px;
}

.subheader-height{
    height: auto !important;
}

.declined-reason-maring{
    margin-left: 0.5rem !important;
}
.declined-reason-sub-maring{
    margin-right: 0.3rem !important;
}

.list-group-item .actions-emailactivity div, .list-group-item-disabled .actions-emailactivity div {
    line-height: 15px !important;
}